<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex">
      <div class="el-lt" style="width: 85%">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="申请单号" :class="$i18n.locale">
              <el-input v-model="form.applyId" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="关联单号" :class="$i18n.locale">
              <el-input v-model="form.orderId" :placeholder="$t('page.inputPlaceholder')" />
            </el-form-item>
          </el-col>

          <el-col :span="9">
            <el-form-item label="申请类型" :class="$i18n.locale">
              <el-select v-model="form.applyType" clearable :placeholder="$t('page.selectPlaceholder')">
                <el-option label="运营申请" value="1" />
                <el-option label="计划释放" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人" :class="$i18n.locale">
              <el-select
                v-model="form.createById"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="item in employeeOptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="7">
            <el-form-item label="审批人" :class="$i18n.locale">
              <el-select
                v-model="form.approveById"
                clearable
                filterable
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option v-for="item in employeeOptions" :key="item.id" :label="item.username" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="申请日期">
              <el-date-picker
                v-model="form.value1"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="审批日期">
              <el-date-picker
                v-model="form.value2"
                type="daterange"
                value-format="yyyy-MM-dd"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>

      </div>
      <el-row class="el-rt" style="width: 10%">
        <el-col class="row-center">
          <el-button type="primary" class="ml-4" :loading="tableDatasLoading" @click="queryClick(1)">{{
            $t("page.search")
          }}</el-button>
          <el-button @click="resetClick()">{{ $t("page.reset") }}</el-button>
          <el-button type="text" @click="showRow=!showRow">{{
            showRow ? $t("page.hide") : $t("page.expand")
          }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-row>
      <el-button type="primary" class="mb-3" @click="add">新建申请单</el-button>
      <el-tabs v-model="form.status" @tab-click="queryClick(1)">
        <el-tab-pane label="待审批" name="0">
          <ApplicationTable :table-datas="tableDatas" :table-datas-loading="tableDatasLoading" />
        </el-tab-pane>
        <el-tab-pane label="审批通过" name="1">
          <ApplicationTable :table-datas="tableDatas" :table-datas-loading="tableDatasLoading" />
        </el-tab-pane>
        <el-tab-pane label="驳回" name="2">
          <ApplicationTable :table-datas="tableDatas" :table-datas-loading="tableDatasLoading" />
        </el-tab-pane>
        <el-tab-pane label="全部" name="">
          <ApplicationTable :table-datas="tableDatas" :table-datas-loading="tableDatasLoading" />
        </el-tab-pane>
      </el-tabs>
    </el-row>
    <Paging :pager="pager" end class="mt-3" @pagination="pagerUpdate" />

  </div>
</template>

<script>
import ApplicationTable from './components/ApplicationTable'
import Paging from '@/components/Pagination'
import { page } from '@/api/safestock'
import { deepClone } from '@/utils'
import { usersList } from '@/api/scm-api'
export default {
  components: { ApplicationTable, Paging },

  data() {
    return {
      employeeOptions: [],
      form: {
        applyStatus: 0
      },
      showRow: false,
      tableDatas: [],
      tableDatasLoading: false,
      pager: {
        current: 1,
        size: 20,
        total: 0
      }
    }
  },
  computed: {
    queryParams() {
      const params = deepClone(this.form)
      const { value1, value2, status } = params
      const [startCreateTime, endCreateTime] = value1 || []
      const [startApproveTime, endApproveTime] = value2 || []
      let applyStatus = ''
      status === '3' ? applyStatus = '' : applyStatus = status
      delete params.value1
      delete params.value2
      return Object.assign({}, this.pager, params, { applyStatus, startCreateTime, endCreateTime, startApproveTime, endApproveTime })
    }
  },
  created() {

  },
  mounted() {
    this.queryClick()
    this._usersList()
  },
  methods: {
    async _usersList() {
      const { datas } = await usersList()
      this.employeeOptions = datas
    },
    tabClick(tab, event) {

    },
    async queryClick(flag) {
      try {
        flag && flag === 1 ? this.pager.current = 1 : ''
        this.tableDatasLoading = true
        const { datas: { pager, records }, code } = await page(this.queryParams)
        if (code === 0) {
          this.tableDatas = records
          this.pager = pager
          this.tableDatasLoading = false
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.tableDatasLoading = false
      }
    },
    resetClick() {
      this.form = this.$options.data.call(this).form
      this.form.status = '0'
      this.queryClick(1)
    },
    pagerUpdate(val) {
      this.pager = val
      this.queryClick()
    },
    add() {
      this.$router.push({
        path: 'add',
        append: 'true'
      })
    }

  }
}
</script>

<style scoped lang="scss">
/deep/.el-range-input {
  width: 90px !important;
}
/deep/.el-date-editor {
  width: 100%;
}
</style>
