<template>
  <div>
    <el-table
      ref="tableDatas"
      v-loading="tableDatasLoading"
      :data="tableDatas"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />
      <el-table-column label="申请单号" min-width="140">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: '/safestock-manage/safestock-applicationForm/details',
              query: {applyId:scope.row.applyId,historyId:scope.row.historyId},
              append:true
            }"
            style="color: #1890ff"
          >{{
            scope.row.applyId
          }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="orderId" label="关联单号" width="120" />

      <el-table-column label="申请类型" width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.applyType==1">运营申请</span>
          <span v-if="scope.row.applyType==2">计划释放</span>
        </template>
      </el-table-column>
      <el-table-column prop="siteGroupName" label="组合" />
      <el-table-column prop="applyPairs" label="申请总数" width="80" />
      <el-table-column prop="releasePairs" width="100px" label="释放总数" />
      <el-table-column prop="warehouseName" width="110px" label="仓库" />
      <el-table-column prop="applyStatus" width="100px" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.applyStatus==0">待审核</span>
          <span v-if="scope.row.applyStatus==1">审核通过</span>
          <span v-if="scope.row.applyStatus==2">驳回</span>
        </template>
      </el-table-column>
      <el-table-column prop="createByName" width="100px" label="申请人" />
      <el-table-column prop="createTime" label="申请时间" />
      <el-table-column prop="approveByName" label="审批人" width="100" />
      <el-table-column prop="approveTime" label="审批时间" width="100" />
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="view(scope.row)">查看</el-button>
          <el-button v-if="scope.row.applyStatus===2" type="text" @click="modify(scope.row)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableDatas: {
      type: Array,
      default() {
        return []
      }
    },
    tableDatasLoading: {
      type: Boolean,
      default: false
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    modify({ applyId, historyId }) {
      this.$router.push({
        path: 'add',
        append: true,
        query: { applyId, historyId }
      })
    },
    view({ applyId, historyId }) {
      this.$router.push({
        path: 'details',
        append: true,
        query: { applyId, historyId }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
