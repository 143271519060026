import request from '@/utils/scm-request'
import requestWms from '@/utils/request'

// 安全库存查询分页查询
export function findSafet(data) {
  return requestWms({
    url: `wms-store/safestore/findSafe`,
    method: 'post',
    data
  })
}

// 新建修改安全库存单
export function safeStoreInfo(data) {
  return requestWms({
    url: `wms-store/safestore/safeStoreInfo`,
    method: 'post',
    data
  })
}

// 库存申请单分页查询
export function page(params) {
  return requestWms({
    url: `wms-store/safestore/page`,
    method: 'get',
    params
  })
}

// 申请单历史记录查询
export function findSafeStoreHistory(applyId) {
  return requestWms({
    url: `wms-store/safestore/findSafeStoreHistory/${applyId}`,
    method: 'get'
  })
}

// 详情表单
export function findSafeStore(applyId) {
  return requestWms({
    url: `wms-store/safestore/findSafeStore/${applyId}`,
    method: 'get'
  })
}

// 详情页table
export function findSafeStoreDetail(applyId, historyId, type) {
  return requestWms({
    url: `wms-store/safestore/findSafeStoreDetail/${applyId}/${historyId}/${type}`,
    method: 'get'
  })
}

// 异常处理table查询
export function pageSafeInventory(params) {
  return requestWms({
    url: `wms-store/store/pageSafeInventory`,
    method: 'get',
    params
  })
}

// 库存组合查询
export function findGroupAllocation() {
  return requestWms({
    url: `/wms-in/rules/findGroupAllocation`,
    method: 'get'
  })
}
// 异常处理库存分配
export function safeAllocation(data) {
  return requestWms({
    url: `wms-store/store/safeAllocation`,
    method: 'put',
    data
  })
}
// 安全库存分页查询style
export function safeStylePage(params) {
  return requestWms({
    url: `wms-store/safestore/safeStylePage`,
    method: 'get',
    params
  })
}

// 安全库存分页查询warehouse
export function safeWarehousePage(params) {
  return requestWms({
    url: `wms-store/safestore/safeWarehousePage`,
    method: 'get',
    params
  })
}

// 根据仓库获取库存组合
export function getGroupByWarehouseId(params) {
  return requestWms({
    url: `wms-base/basewarehouse/getWarehouseGroupId`,
    method: 'get',
    params
  })
}

// 安全库存的style
export function safeStyle(params) {
  return requestWms({
    url: `wms-store/safestore/safeStyle`,
    method: 'get',
    params
  })
}

// 未结延期报表
export function reportUnclearedDelayGroupBy(params) {
  return request({
    url: `scm-purchase/statistics/reportUnclearedDelayGroupBy`,
    method: 'get',
    params
  })
}

// 未结延期概览数据
export function reportUnclearedDelayOverview(params) {
  return request({
    url: `scm-purchase/statistics/reportUnclearedDelayOverview`,
    method: 'get',
    params
  })
}

// 应交准交概览数据
export function reportOtddOverview(params) {
  return request({
    url: `scm-purchase/statistics/reportOtddOverview`,
    method: 'get',
    params
  })
}
// 应交准交月维度折线图（供应商/BU）
export function chartOtddByMonth(params) {
  return request({
    url: `scm-purchase/statistics/chartOtddByMonth`,
    method: 'get',
    params
  })
}

// 应交准交-按年统计应交准交（供应商、BU）堆叠柱状图
export function chartOtddByYear(params) {
  return request({
    url: `scm-purchase/statistics/chartOtddByYear`,
    method: 'get',
    params
  })
}

// 应交准交-按年统计评审准交（供应商、BU）堆叠柱状图
export function chartOtddReviewByYear(params) {
  return request({
    url: `scm-purchase/statistics/chartOtddReviewByYear`,
    method: 'get',
    params
  })
}

// 未结报表延期明细数据pageUnclearedDelayDetail
export function pageUnclearedDelayDetail(params) {
  return request({
    url: `scm-purchase/statistics/pageUnclearedDelayDetail`,
    method: 'get',
    params
  })
}

// 应交准交明细
export function pageOtddDetail(params) {
  return request({
    url: `scm-purchase/statistics/pageOtddDetail`,
    method: 'get',
    params
  })
}

// 应交准交明细PO维度  pageOtddPo
export function pageOtddPo(params) {
  return request({
    url: `scm-purchase/statistics/pageOtddPo`,
    method: 'get',
    params
  })
}

// 未结报表上面的所有图
export function chartUnclearedDelayByAll(params) {
  return request({
    url: `scm-purchase/statistics/chartUnclearedDelayByAll`,
    method: 'get',
    params
  })
}

// 未结新增
export function chartAddDelayOrder(params) {
  return request({
    url: `scm-purchase/statistics/chartAddDelayOrder`,
    method: 'get',
    params
  })
}

// 继续延期图表
export function chartContinueDelayOrder(params) {
  return request({
    url: `scm-purchase/statistics/chartContinueDelayOrder`,
    method: 'get',
    params
  })
}

//  延期原因
export function getDelayReason(params) {
  return request({
    url: `scm-purchase/basic/getDelayReason`,
    method: 'get',
    params
  })
}

// 导出应交明细表
export function exportOtddDetailList(params) {
  return request({
    url: `scm-purchase/excel/exportOtddDetailList`,
    method: 'get',
    params,
    responseType: 'arraybuffer'

  })
}

// PO导出
export function exportOtddPoList(params) {
  return request({
    url: `scm-purchase/excel/exportOtddPoList`,
    method: 'get',
    params,
    responseType: 'arraybuffer'

  })
}

// 导出未结延期表
export function exportUnclearedList(params) {
  return request({
    url: `scm-purchase/excel/exportUnclearedList`,
    method: 'get',
    params
  })
}

// 安全库存物理仓1/逻辑仓0
export function getWarehouse(data) {
  return requestWms({
    url: `wms-base/basewarehouse/getWarehouse`,
    method: 'post',
    data
  })
}
// 根据物理仓拿逻辑仓
export function getLogicWarehouse(data) {
  return requestWms({
    url: `wms-base/basewarehouse/getLogicWarehouse`,
    method: 'post',
    data
  })
}

export function pageOtddCategory(params) {
  return request({
    url: `scm-purchase/statistics/pageOtddCategory`,
    method: 'get',
    params
  })
}

export function exportOtddCategoryList(params) {
  return request({
    url: `scm-purchase/excel/exportOtddCategoryList`,
    method: 'get',
    params,
    responseType: 'arraybuffer'
  })
}

export function otddCategoryTotal(params) {
  return request({
    url: `scm-purchase/statistics/otddCategoryTotal`,
    method: 'get',
    params
  })
}

// 更新LT不达标原因考核配置
export function ltSubstandardConfig(data) {
  return request({
    url: `scm-purchase/statistics/ltSubstandardConfig`,
    method: 'post',
    data
  })
}
// 查看LT不达标原因考核配置
export function ltSubstandardConfigList() {
  return request({
    url: `scm-purchase/statistics/ltSubstandardConfig`,
    method: 'get'
  })
}

// 分页LT不达标原因考核设置记录
export function pageSubstandardConfigLog(params) {
  return request({
    url: `scm-purchase/statistics/pageSubstandardConfigLog`,
    method: 'get',
    params
  })
}
